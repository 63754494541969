.searchBoxReg {
  display: flex;
  position: relative;
  height: 50px;
  width: 100%; /* Ensure this matches the intended width */
}

.searchBoxReg::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  width: 25px; /* Match this with the paddingLeft of the TextField */
  height: 100%; /* Adjust based on your design */
  background: url('../../assets/EU-Stars.png') no-repeat center center, #1e2f7b;
  background-size: contain;
  border-radius: 5px 0 0 5px;
}
